import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div class="px-4 mt-2 mb-5 text-center">
      <h1 class="display-5 mb-4 fw-bold">Testimonials</h1>
      <div class="col-lg-6 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Anthony Green: </strong>Head of Global Human Resources
          Processes, LSG Group
        </p>
        <p class="text-start fst-italic">
          A personable and extremely professional individual who always
          delivered great service and always reacted quickly to the
          customer&#39;s needs.
        </p>
      </div>

      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Deanna Teresa Rasmussen: </strong>Global Mobility Manager,
          NEME, APAC &amp; India, Siemens Gamesa
        </p>
        <p class="text-start fst-italic">
          I have had the pleasure to work with Paul over the last 15 yrs. Paul
          is professional and always looks after his clients in a very
          professional manner. Paul is always looking for ways to improve client
          manager relationships and understands the clients “point of view” he
          is a strategic thinker and I have always appreciated working with Paul
          regardless of what Company he has represented over the years.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Marisa Locke: </strong>International Mobility Relationship
          Manager, Vodafone
        </p>
        <p class="text-start fst-italic">
          I worked with Paul as a client, during his time at The MI Group. Paul
          is the ultimate professional, providing excellent support under any
          circumstances. His knowledge of the industry and experience is second
          to none, which led to me to feel both informed and reassured during
          somewhat unknown times. I highly recommend working with Paul.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Matt Lunec: </strong>Senior Global Mobility Manager, Marsh
          McLennan
        </p>
        <p class="text-start fst-italic">
          I have worked with Paul as a client during his time with the MI Group
          and previously when he was working for Team Relocations. Paul is a
          leader in the industry, he is exactly what you want in an Operations
          Director, client focused, solutions orientated and a real people
          person. I really cannot recommend Paul enough and hope to work with
          him again in the future.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Michael A. Piker: </strong>Global HR Vice President · Total
          Rewards · Talent Mobility · Agile Methodology · People Analytics · HR
          Technology · Adjunct Lecturer · Investor
        </p>
        <p class="text-start fst-italic">
          I had the great fortune of working with Paul. He is empathetic, humble
          and solves client issues quickly. At Naspers we ran a gruelling RFP
          process for a new Global RMC and his firm won the bid on its merits. I
          recommend Paul and his firm.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Michelle Yates: </strong>Global Mobility Specialist
        </p>
        <p class="text-start fst-italic">
          I worked with Paul when he was at Weichert and the MI Group, as a
          client. His dedication to customer service; building collaborative
          relationships and his operational expertise ensured it was a pleasure
          to work with Paul and his team. Paul is the ultimate professional
          while always maintaining a friendly disposition which made for an
          engaging service delivery experience.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Sarah Kenning: </strong>Strategic Global Marketing Leader
        </p>
        <p class="text-start fst-italic">
          I had the pleasure of working with Paul for several years and I
          enjoyed every opportunity we had to collaborate. Paul has a great gift
          for thinking differently and identifying unique solutions that really
          meet the specific needs of his clients - needs they may not even be
          aware of. He is strategic and focused in his approach, and very
          knowledgeable about Global Mobility. This was never more evident than
          when we were working on RFPs together - his thoughtful responses and
          attention to detail resulted in an exceptional deliverable every time.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>

      <div class="col-lg-6 mt-4 mb-4 mx-auto">
        <p class="lead mb-4 text-start text-coolblue">
          <strong>Siobhan Cummins: </strong>Strategic Mobility Advisor
        </p>
        <p class="text-start fst-italic">
          I worked with Paul at both MI and Weichert. He is the consummate
          professional always going that extra mile to solve problems for his
          clients. From the start of our relationship with MI and subsequently
          Weichert Paul was always ready to respond to the numerous challenges
          we presented him with and responded quickly and creatively. He is the
          ultimate diplomat, and it was a pleasure to work with him and his
          team.
        </p>
      </div>
      <div class="col-sm-5 border-bottom border-coolorange mx-auto"></div>
    </div>
  </Layout>
)

export default contact
